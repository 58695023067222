import React from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import './App.css';
import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
// import CurrencyFormat from './components/formats/CurrencyFormat';
import useNotification from './hooks/useNotification';
// import { number } from 'yup';
import Table from './Table';

const BaseComponent = () => {
  // uy sot
  const paymentsAxios = axios.create({
    baseURL: 'https://service.app.uysot.uz/v1/contract/',
  });

  const uySotGetContractAxios = axios.create({
    baseURL: 'https://service.app.uysot.uz/v1/contract/',
  });

  const [uysotPaymentsHistoryList, setUysotPaymentsHistoryList] = useState([]);
  const [uySotToken, setUySotToken] = useState('');
  const [uySotPaymentsHistoryLoading, setUySotPaymentsHistoryLoading] =
    useState(false);
  const [saleHouseToken, setSaleHouseToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uySotContractId, setUySotContractId] = useState('');
  const [saleHouseContractId, setSaleHouseContractId] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [uySotContractData, setUySotContractData] = useState({});
  const [saleHouseContractData, setSaleHouseContractData] = useState({});
  const sendNotification = useNotification();

  const getUySotPaymentHistory = async () => {
    try {
      setUySotPaymentsHistoryLoading(true);
      let results = await paymentsAxios.get(`${uySotContractId}/payment`, {
        headers: {
          Authorization: `Bearer ${uySotToken}`,
        },
      });
      // console.log("results = ", results)
      let uysotContract = await uySotGetContractAxios.get(uySotContractId, {
        headers: {
          Authorization: `Bearer ${uySotToken}`,
        },
      });
      // console.log("uysotContract = ", uysotContract)

      let saleHouseContract = await axios.get(
        `${
          baseUrl
            ? baseUrl?.endsWith('/')
              ? baseUrl
              : baseUrl + '/'
            : 'https://backsmartsales.uz/api/'
        }admin/contract/edit/${saleHouseContractId}`,
        {
          headers: {
            Authorization: `Bearer ${saleHouseToken}`,
          },
        }
      );
      // console.log("saleHouseContract = ", saleHouseContract)
      if (
        results &&
        results.data &&
        results.data.data &&
        uysotContract &&
        uysotContract.data &&
        uysotContract.data.data &&
        saleHouseContract &&
        saleHouseContract.data &&
        saleHouseContract.data.status
      ) {
        setUySotPaymentsHistoryLoading(false);
        mutatePaymentsHistory(results.data.data);
        setUySotContractData(uysotContract.data.data);
        setSaleHouseContractData(saleHouseContract.data.data);
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
      setUySotPaymentsHistoryLoading(false);
    }
  };

  const mutatePaymentsHistory = (data) => {
    let mutatedData = data.map((item) => ({
      id: item.id,
      sum: item.amount,
      number: item.number,
      type_id: getByUySotPaymentType(item.type),
      date: item.createdDate
        ? moment(item.createdDate, 'DD.MM.YYYY hh:mm').format('YYYY-MM-DD')
        : '',
      sent: false,
    }));
    console.log('mutatedData = ', mutatedData);
    setUysotPaymentsHistoryList(mutatedData);
  };

  const getByUySotPaymentType = (type) => {
    let mutatedType = '';
    switch (type) {
      case 'CASH': {
        mutatedType = '1';
        break;
      }
      case 'TRANSFER': {
        mutatedType = '2';
        break;
      }
      case 'BANK': {
        mutatedType = '3';
        break;
      }
      case 'CARD': {
        mutatedType = '4';
        break;
      }
      default: {
        mutatedType = '1';
        break;
      }
    }
    return mutatedType;
  };

  //   const sendPaymentsHistory = () => {
  //     handleRequestRecursion(uysotPaymentsHistoryList.length - 1);
  //   };

  //   const handleRequestRecursion = async (index) => {
  //     if (index === -1) {
  //       return 'Hello';
  //     }
  //     // console.log(
  //     // 	"handleRequestRecursion value = ",
  //     // 	uysotPaymentsHistoryList[index]
  //     // )
  //     let newItemData = {
  //       sum: uysotPaymentsHistoryList[index].sum,
  //       type_id: uysotPaymentsHistoryList[index].type_id,
  //       date: uysotPaymentsHistoryList[index].date,
  //       number: uysotPaymentsHistoryList[index].number,
  //       comment: '',
  //       isvalute: '0',
  //     };
  //     try {
  //       let result = await axios.post(
  //         `${
  //           baseUrl
  //             ? baseUrl?.endsWith('/')
  //               ? baseUrl
  //               : baseUrl + '/'
  //             : 'https://backsmartsales.uz/api/'
  //         }admin/payment/store/${saleHouseContractId}`,
  //         JSON.stringify(newItemData),
  //         {
  //           headers: {
  //             Authorization: `Bearer ${saleHouseToken}`,
  //             'Content-Type': 'application/json',
  //           },
  //         }
  //       );
  //       if (result && result.data && result.nstatus) {
  //         sendNotification({
  //           msg: `Shartnoma №${saleHouseContractId} ga to'lov muvaffaqiyatli amalga oshirildi!`,
  //           variant: 'success',
  //         });
  //         // setIsSubmitting(false)
  //         changeUysotPaymentsHistoryList(uysotPaymentsHistoryList[index].id);
  //         await handleRequestRecursion(index - 1);
  //       }
  //     } catch (error) {
  //       sendNotification({
  //         msg: `${
  //           error?.response?.data?.message || error?.message
  //         }. Error on date: ${uysotPaymentsHistoryList[index].date}, sum: ${
  //           uysotPaymentsHistoryList[index].sum
  //         }`,
  //         variant: 'error',
  //       });
  //       // await handleRequestRecursion(index - 1)
  //       return 'Hello';
  //     }
  //   };

  const sendSinglePaymentHistory = async (itemData) => {
    let newItemData = {
      sum: itemData.sum,
      type_id: itemData.type_id,
      date: itemData.date,
      number: itemData.number,
      comment: '',
      isvalute: '0',
    };
    try {
      let result = await axios.post(
        `${
          baseUrl
            ? baseUrl?.endsWith('/')
              ? baseUrl
              : baseUrl + '/'
            : 'https://backsmartsales.uz/api/'
        }admin/payment/store/${saleHouseContractId}`,
        JSON.stringify(newItemData),
        {
          headers: {
            Authorization: `Bearer ${saleHouseToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (result && result.data && result.status) {
        sendNotification({
          msg: `Shartnoma №${saleHouseContractId} ga to'lov muvaffaqiyatli amalga oshirildi!`,
          variant: 'success',
        });
        setIsSubmitting(false);
        changeUysotPaymentsHistoryList(itemData.id);
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  const changeUysotPaymentsHistoryList = (id) => {
    let newUysotPaymentsHistoryList = [...uysotPaymentsHistoryList];
    newUysotPaymentsHistoryList.forEach((item) => {
      if (item.id === id) {
        item.sent = true;
      }
    });
    // console.log("newUysotPaymentsHistoryList = ", newUysotPaymentsHistoryList)
    setUysotPaymentsHistoryList(newUysotPaymentsHistoryList);
  };

  return (
    <div>
      <div className='base-wrapper'>
        <div className='site-1 site'>
          <h3>UySot Shartnoma</h3>
          <TextField
            color='primary'
            variant='outlined'
            id='site-1-token'
            name='site-1-token'
            label='UySot token'
            autoComplete='off'
            value={uySotToken}
            onInput={(event) => setUySotToken(event.target?.value)}
            multiline
            rows={4}
            fullWidth
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            color='primary'
            variant='outlined'
            id='uysot-contract-id'
            name='uysot-contract-id'
            label='UYSOT Kontrakt id'
            autoComplete='off'
            value={uySotContractId}
            onInput={(event) => setUySotContractId(event.target?.value)}
            sx={{ width: '300px', marginBottom: '1rem' }}
          />

          {uySotContractData && (
            <div>
              <div className='uysot-contract-data'>
                <h4>Mijoz nomi: </h4>
                <span>
                  {uySotContractData?.clientDto?.firstname}{' '}
                  {uySotContractData?.clientDto?.lastname}{' '}
                  {uySotContractData?.clientDto?.fathersName}
                </span>
              </div>
              <div className='uysot-contract-data'>
                <h4>Shartnoma raqami: </h4>
                <span>{uySotContractData?.number}</span>
              </div>
            </div>
          )}

          <Button
            color='success'
            variant='contained'
            type='submit'
            disabled={
              uySotPaymentsHistoryLoading ||
              !uySotContractId ||
              !uySotToken ||
              !saleHouseToken ||
              !saleHouseContractId
            }
            onClick={() => getUySotPaymentHistory()}
          >
            {uySotPaymentsHistoryLoading && (
              <CircularProgress size={15} color='inherit' className='mr-1' />
            )}
            To'lovlar tarixini olish
          </Button>
        </div>
        <div className='site-2 site'>
          <TextField
            color='primary'
            variant='outlined'
            id='salehouse-contract-id'
            name='salehouse-contract-id'
            label='Base url of our company'
            placeholder='https://baseurl.uz/api/'
            autoComplete='off'
            value={baseUrl}
            onInput={(event) => setBaseUrl(event.target?.value)}
            sx={{ width: '100%' }}
          />
          <h3>SaleHouse Shartnoma</h3>
          <TextField
            color='primary'
            variant='outlined'
            id='site-2-token'
            name='site-2-token'
            label='SaleHouse token'
            autoComplete='off'
            value={saleHouseToken}
            onInput={(event) => setSaleHouseToken(event.target?.value)}
            multiline
            rows={4}
            fullWidth
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            color='primary'
            variant='outlined'
            id='salehouse-contract-id'
            name='salehouse-contract-id'
            label='SALEHOUSE Kontrakt id'
            autoComplete='off'
            value={saleHouseContractId}
            onInput={(event) => setSaleHouseContractId(event.target?.value)}
            sx={{ width: '300px', marginBottom: '1rem' }}
          />

          {saleHouseContractData && (
            <div>
              <div className='uysot-contract-data'>
                <h4>Mijoz nomi: </h4>
                <span>
                  {saleHouseContractData?.custom?.name}{' '}
                  {saleHouseContractData?.custom?.surname}{' '}
                  {saleHouseContractData?.custom?.middlename}
                </span>
              </div>
              <div className='uysot-contract-data'>
                <h4>Shartnoma raqami: </h4>
                <span>{saleHouseContractData?.name}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <Table
          uysotPaymentsHistoryList={uysotPaymentsHistoryList}
          sendSinglePaymentHistory={sendSinglePaymentHistory}
          baseUrl={baseUrl}
          saleHouseContractId={saleHouseContractId}
          saleHouseToken={saleHouseToken}
          changeUysotPaymentsHistoryList={changeUysotPaymentsHistoryList}
        />
        {/* <div className='send-btn'>
          <Button
            color='secondary'
            variant='contained'
            type='submit'
            disabled={
              isSubmitting ||
              !saleHouseContractId ||
              !saleHouseToken ||
              !uysotPaymentsHistoryList.length
            }
            onClick={() => sendPaymentsHistory()}
          >
            {isSubmitting && (
              <CircularProgress size={15} color='inherit' className='mr-1' />
            )}
            To'lovlarni yuborish
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default BaseComponent;
