import React, { memo, useEffect, useState } from 'react';
import { Button, TableCell, TableRow, CircularProgress } from '@mui/material';
import axios from 'axios';
import useNotification from './hooks/useNotification';

const PAYMENT_TYPE = {
  CASH: { code: '1', label: 'Naqd' },
  PSP: { code: '2', label: 'P2P' },
  BANK: { code: '3', label: 'Bank' },
  CARD: { code: '4', label: 'Karta' },
  ACT: { code: '5', label: 'AKT' },
};

const variants = [
  {
    code: PAYMENT_TYPE.CASH.code,
    label: PAYMENT_TYPE.CASH.label,
    color: 'warning',
  },
  {
    code: PAYMENT_TYPE.PSP.code,
    label: PAYMENT_TYPE.PSP.label,
    color: 'info',
  },
  {
    code: PAYMENT_TYPE.BANK.code,
    label: PAYMENT_TYPE.BANK.label,
    color: 'secondary',
  },
  {
    code: PAYMENT_TYPE.CARD.code,
    label: PAYMENT_TYPE.CARD.label,
    color: 'success',
  },
  {
    code: PAYMENT_TYPE.ACT.code,
    label: PAYMENT_TYPE.ACT.label,
    color: 'error',
  },
];
function Row({
  row,
  baseUrl,
  saleHouseContractId,
  saleHouseToken,
  changeUysotPaymentsHistoryList,
}) {
  const sendNotification = useNotification();
  const [data, setData] = useState(row);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    setData(row);
  }, [row]);
  const onChange = (e) => {
    const { value, name } = e?.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const onSelect = (e) => {
    const { value, name } = e?.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const sendSinglePaymentHistory = async (itemData) => {
    setIsSubmitting(true);
    let newItemData = {
      sum: itemData.sum,
      type_id: itemData.type_id,
      date: itemData.date,
      number: itemData.number,
      comment: '',
      isvalute: '1',
    };
    try {
      let result = await axios.post(
        `${
          baseUrl
            ? baseUrl?.endsWith('/')
              ? baseUrl
              : baseUrl + '/'
            : 'https://backsmartsales.uz/api/'
        }admin/payment/store/${saleHouseContractId}`,
        JSON.stringify(newItemData),
        {
          headers: {
            Authorization: `Bearer ${saleHouseToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (result && result.data && result.status) {
        sendNotification({
          msg: `Shartnoma №${saleHouseContractId} ga to'lov muvaffaqiyatli amalga oshirildi!`,
          variant: 'success',
        });
        setIsSubmitting(false);
        changeUysotPaymentsHistoryList(itemData.id);
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <input name='number' value={data.number} onChange={onChange} />
      </TableCell>
      <TableCell>
        <input name='date' value={data.date} onChange={onChange} />
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex' }}>
          <input name='sum' value={data.sum} onChange={onChange} /> UZS
        </div>
      </TableCell>
      <TableCell>
        <select
          name='type_id'
          id='type_id'
          value={data?.type_id}
          onChange={onSelect}
        >
          {variants.map((variant) => (
            <option key={variant.code} value={variant.code}>
              {variant.label}
            </option>
          ))}
        </select>
      </TableCell>
      <TableCell>
        <Button
          color='secondary'
          variant='contained'
          fullWidth
          onClick={() => sendSinglePaymentHistory(data)}
          disabled={row.sent || isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={25} color='inherit' className='mr-1' />
          ) : row.sent ? (
            'Yuborilgan'
          ) : (
            'Yuborish'
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default memo(Row);
