import React, { memo } from 'react';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Row from './TableRow';

function Table({
  uysotPaymentsHistoryList,
  baseUrl,
  saleHouseContractId,
  saleHouseToken,
  changeUysotPaymentsHistoryList,
}) {
  return (
    <TableContainer className='payment-history-table'>
      <MUITable
        stickyHeader
        sx={{
          width: 750,
          height: 'max-content',
          margin: '0 auto',
          border: '1px solid red',
        }}
        aria-labelledby='tableTitle'
      >
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Sana</TableCell>
            <TableCell>Summasi</TableCell>
            <TableCell>To'lov turi</TableCell>
            <TableCell>Amallar</TableCell>
          </TableRow>
        </TableHead>
        {uysotPaymentsHistoryList && uysotPaymentsHistoryList.length > 0 ? (
          <TableBody className='overflow-hidden'>
            {uysotPaymentsHistoryList.map((row, rowIndex) => {
              return (
                <Row
                  row={row}
                  key={rowIndex}
                  baseUrl={baseUrl}
                  saleHouseContractId={saleHouseContractId}
                  saleHouseToken={saleHouseToken}
                  changeUysotPaymentsHistoryList={
                    changeUysotPaymentsHistoryList
                  }
                />
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <span className='text-gray-400 flex items-center justify-center'>
                  Ma'lumotlar topilmadi!
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </MUITable>
    </TableContainer>
  );
}

export default memo(Table);
