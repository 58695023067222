
import { SnackbarProvider } from "notistack"
import BaseComponent from "./BaseComponent"

function App() {

	const snackBarOptions = {
		maxSnack: 5
	}

	return (
		<SnackbarProvider {...snackBarOptions}>
			<BaseComponent/>
		</SnackbarProvider>
	)
}

export default App
